'use client';
import { useState, useEffect } from 'react';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import CloseSVG from '@/assets/svg/icons/close';
import COLORS from '@/lib/helpers/color-helper';

interface BookSearchFormProps {
  initialQuery?: string;
}

export default function BookSearchForm({
  initialQuery = '',
}: BookSearchFormProps) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [searchQuery, setSearchQuery] = useState(initialQuery);
  const [inputIsEmpty, setInputIsEmpty] = useState(!initialQuery);

  // When initialQuery from URL changes, update local state
  useEffect(() => {
    setSearchQuery(initialQuery);
    setInputIsEmpty(!initialQuery);
  }, [initialQuery]);

  // Handle input change
  const handleInputChange = (value: string) => {
    setSearchQuery(value);
    setInputIsEmpty(value.length === 0);
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Create a new URLSearchParams and preserve existing parameters except page
    const params = new URLSearchParams(searchParams);

    // Update or remove the query parameter
    if (searchQuery.trim()) {
      params.set('q', searchQuery);
    } else {
      params.delete('q');
    }

    // Reset to page 1 when search changes
    params.set('page', '1');

    // Navigate to the new URL
    router.push(`${pathname}?${params.toString()}`, { scroll: false });
  };

  // Clear search
  const handleClearSearch = () => {
    setSearchQuery('');
    setInputIsEmpty(true);
    updateSearchParams('');
  };

  // Update URL search params
  const updateSearchParams = (query: string) => {
    const params = new URLSearchParams(searchParams);

    // Update query parameter
    if (query) {
      params.set('q', query);
    } else {
      params.delete('q');
    }

    // Reset to page 1 when search changes
    params.set('page', '1');

    // Navigate with new params
    router.push(`${pathname}?${params.toString()}`, { scroll: false });
  };

  // Render clear button or search icon
  const renderClearOrSearchIcon = () => {
    if (!searchQuery) {
      return (
        <span className="absolute right-3 top-1/2 -translate-y-1/2 pointer-events-none flex items-center justify-center">
          <div className="w-5 h-5 flex items-center justify-center">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
            >
              <rect
                x="16"
                y="17.6263"
                width="2.3"
                height="9"
                rx="1.15"
                transform="rotate(-45 16 17.6263)"
                fill={COLORS.lightGrey}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM10 16.6C13.6451 16.6 16.6 13.6451 16.6 10C16.6 6.35495 13.6451 3.40003 10 3.40003C6.35495 3.40003 3.40002 6.35495 3.40002 10C3.40002 13.6451 6.35495 16.6 10 16.6Z"
                fill={COLORS.lightGrey}
              />
            </svg>
          </div>
        </span>
      );
    }

    return (
      <button
        type="button"
        onClick={handleClearSearch}
        className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer bg-transparent border-0 p-0 flex items-center justify-center"
      >
        <CloseSVG strokeColor={COLORS.grey} width={20} height={20} />
      </button>
    );
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit} className="relative w-full">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => handleInputChange(e.target.value)}
          className="w-full py-3 px-4 border border-gray-200 rounded-2xl bg-white shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-400 pr-10"
          placeholder="Search books"
          aria-label="Search books"
        />
        {renderClearOrSearchIcon()}
      </form>
    </div>
  );
}
